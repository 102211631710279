<template>
  <div class="view-account container-fluid py-5">
    <div class="row">
      <div class="col-lg-6">
        <form class="card border-0 shadow" @submit.prevent="submit">
          <div class="card-body">
            <h3 class="font-weight-bold mb-4">Minha conta</h3>

            <form-group
              id="email"
              v-model="form.email"
              :errors="errors.email"
              type="email"
              placeholder="Seu e-mail"
              readonly
            />

            <form-group
              id="name"
              v-model="form.name"
              :errors="errors.name"
              type="text"
              placeholder="Seu nome"
            />

            <form-group
              id="phone"
              v-model="form.phone"
              :errors="errors.phone"
              type="text"
              placeholder="Número de celular"
            />

            <div class="text-center pt-3">
              <button
                type="submit"
                class="btn btn-danger px-4"
                :disabled="loading"
              >
                <loading :show="loading">Salvar</loading>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-6">
        <change-password />
      </div>
    </div>
  </div>
</template>

<script>
import { formFields } from "@/functions/helpers.js";
import ChangePassword from "@/components/account/ChangePassword";

export default {
  components: { ChangePassword },
  data() {
    return {
      loading: false,
      ...formFields(["name", "phone", "email"], {
        ...this.$user.user
      })
    };
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("user/update", this.form)
        .then(() => {
          this.$message.success("Salvo com sucesso");
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
